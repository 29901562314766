import './PageHeader.scss';
import { IPageHeader } from '../../interfaces/components';
import { useGetRandomMovieQuery } from '../../app/services/movieServerApi';
import { useGetRandomEpisodeQuery } from '../../app/services/tvShowServerApi';
import { useGetRandomAutographQuery } from '../../app/services/autographServerApi';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const PageHeader = ({ title }: IPageHeader) => {
  const location = useLocation();

  const [skipGetRandomMovieItem, setSkipGetRandomMovieItem] = useState(true);
  const [skipGetRandomEpisodeItem, setSkipGetRandomEpisodeItem] =
    useState(true);
  const [skipGetRandomAutographItem, setSkipGetRandomAutographItem] =
    useState(true);

  const {
    data: movieItem = undefined,
    // isLoading: isLoadingMovieItem,
    // // isFetching,
    // isError: isErrorMovieItem,
    // error: errorMovieItem,
  } = useGetRandomMovieQuery({type: 'header', mode: 'background'}, {
    skip: skipGetRandomMovieItem,
  });
  const {
    data: episodeItem = undefined,
    // isLoading: isLoadingEpisodeItem,
    // // isFetching,
    // isError: isErrorEpisodeItem,
    // error: errorEpisodeItem,
  } = useGetRandomEpisodeQuery({type: 'header', mode: 'background'}, {
    skip: skipGetRandomEpisodeItem,
  });
  const {
    data: autographItem = undefined,
    // isLoading: isLoadingAutographItem,
    // // isFetching,
    // isError: isErrorAutographItem,
    // error: errorAutographItem,
  } = useGetRandomAutographQuery({type: 'header', mode: 'background'}, {
    skip: skipGetRandomAutographItem,
  });

  let randomItem = undefined;

  if (location.pathname.includes('autographs')) {
    randomItem = autographItem;
  } else if (location.pathname.includes('tv-shows')) {
    randomItem = episodeItem;
  } else {
    randomItem = movieItem;
  }

  useEffect(() => {
    if (location.pathname.includes('autographs')) {
      setSkipGetRandomAutographItem(false);
    } else if (location.pathname.includes('tv-shows')) {
      setSkipGetRandomEpisodeItem(false);
    } else {
      setSkipGetRandomMovieItem(false);
    }
  }, [location.pathname]);

  const background = randomItem?.background
    ? randomItem?.background
    : randomItem?.cover
      ? randomItem?.cover
      : randomItem?.image;
  return (
    <div
      className="page-header"
      style={{ backgroundImage: `url(${background})`, backgroundPosition: `${randomItem?.backgroundHorizontalPosition}% ${randomItem?.backgroundVerticalPosition}%` }}
    >
      <h2 className="media-h2">{title}</h2>
    </div>
  );
};

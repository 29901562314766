import { setMovieData } from '../slices/addMediaSlice';
import { RootState } from '../store';
import { mediaManagerServerApi } from './mediaManagerServerApi';
// import { navigationSlice } from '../slices/navigationSlice';
// import { setMoviePageStatus } from '../slices/navigationSlice';
// import { useAppDispatch } from '../hooks';

// const dispatch = useAppDispatch();

export const extendedApiSlice = mediaManagerServerApi.injectEndpoints({
  endpoints: (builder) => ({
    getMovies: builder.query<any, any | void>({
      query: (object) =>
        `movie/movies/?page=${object?.page || 1}&mode=${object?.mode || ''}&keyword=${object?.keyword || ''}&tag=${object?.tag || ''}&collection=${object?.collection || ''}&studio=${object?.studio || ''}`, //&page_size=12
      // providesTags: ['Movie'],
      // providesTags: (result = [], error, arg) => [

      // async onQueryStarted(id, { dispatch, queryFulfilled }) {
      //   // `onStart` side-effect
      //   // dispatch(messageCreated('Fetching post...'))
      //   try {
      //     const { data } = await queryFulfilled
      //     // `onSuccess` side-effect
      //     dispatch(setMoviePageStatus({currentPage: data.currentPage, totalPages: data.totalPages}))
      //   } catch (err) {
      //     // `onError` side-effect
      //     // dispatch(messageCreated('Error fetching post!'))
      //   }
      // },

      transformResponse: (rawResult: {
        results: any;
        currentPage: number;
        totalPages: number;
      }) => {
        // // mediaManagerServerApi.
        // console.log('RAW RESPONSE:', rawResult);
        // // console.log("CURRENT PAGE:", rawResult.currentPage)
        // // console.log("TOTAL PAGES:", rawResult.totalPages)
        // // dispatch(setMoviePageStatus({currentPage: rawResult.currentPage, totalPages: rawResult.totalPages}))
        // return rawResult.results;
        // console.log('I ENTERED THE TRANSFORM');
        return {
          results: rawResult.results,
          currentPage: rawResult.currentPage,
          totalPages: rawResult.totalPages,
        };
      },

      // providesTags: (result = {}) => [
      //   'Movie',
      //   ...result?.results?.map(({ id }: { id: number }) => ({
      //     type: 'Movie',
      //     id,
      //   })),
      // ],

      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.results.map(({ id }: { id: number }) => ({ type: 'Movies' as const, id })),
      //         { type: 'Movies', id: 'LIST' },
      //       ]
      //     : [{ type: 'Movies', id: 'LIST' }],

      // providesTags: (result, error, arg) =>
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }: { id: number }) => ({
                type: 'Movie' as const,
                id,
              })),
              'Movie',
            ]
          : ['Movie'],

      // serializeQueryArgs: ({ endpointName }) => {
      //   return endpointName
      // },
      serializeQueryArgs: ({ queryArgs }) => {
        // console.log("serializeQueryArgs");
        // console.log('QUERY ARGS:', queryArgs);
        if (queryArgs) {
          const { page, ...otherArgs } = queryArgs;
          page;
          // console.log('PAGE:', page);
          // console.log("OTHER ARGS:", otherArgs)
          return otherArgs;
          // return page
          // return queryArgs
        } else {
          return queryArgs;
        }
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        // console.log('currentCache:', currentCache);
        // console.log('newItems:', newItems);

        //#1
        currentCache.results.push(...newItems.results);
        currentCache.currentPage = newItems.currentPage;
        //#2
        // if (newItems.currentPage > currentCache.currentPage) {
        //   currentCache.results.push(...newItems.results);
        //   currentCache.currentPage = newItems.currentPage;
        // } else {
        //   currentCache.results = newItems.results;
        //   currentCache.currentPage = newItems.currentPage;
        // }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        // console.log('currentArg:', currentArg);
        // console.log('previousArg:', previousArg);
        // return currentArg !== previousArg
        // console.log('CURRENT ARG PAGE:', currentArg?.page);
        // console.log('PREVIOUS ARG PAGE:', previousArg?.page);
        // console.log(
        //   'PAGES DIFF RESULT:',
        //   currentArg?.page > previousArg?.page,
        // );
        // return currentArg?.page !== previousArg?.page;
        // return currentArg !== previousArg;
        //#1
        return currentArg?.page > previousArg?.page;
        //#2
        // return currentArg?.page !== previousArg?.page;
      },
    }),
    getMovie: builder.query<any, string | undefined>({
      query: (id) => `movie/movies/${id}/`,
      // providesTags: ['Movie'],
      providesTags: (result, error, arg) => [{ type: 'Movie', id: arg }],
    }),
    getRandomMovie: builder.query<any, any | void>({
      query: (object) => `movies/get_random_movie/?type=${object?.type || ''}&mode=${object?.mode || ''}`,
      // providesTags: ['Movie'],
      providesTags: (result, error, arg) => [{ type: 'Movie', id: arg }],
    }),

    getItunesAndImdbMovieOptions: builder.query<any, any | void>({
      query: (object) =>
        `movies/get_itunes_and_imdb_movie_options/?movie_title=${object.movieTitle}&itunes_country=${object?.itunesCountry ? object.itunesCountry.value : null}`,
      transformResponse: (rawResult: {
        itunesSearchMovieResults: any;
        imdbSearchMovieResults: any;
      }) => {
        const processedItunesSearchResults =
          rawResult.itunesSearchMovieResults.map((searchResult: any) => {
            return {
              title: searchResult.trackName,
              cover: searchResult.artworkUrl100.replace(
                '100x100bb',
                '100000x100000-999',
              ),
              id: searchResult.trackId,
            };
          });
        // console.log(
        //   'processedItunesSearchResults:',
        //   processedItunesSearchResults,
        // );
        const processedImdbSearchResults =
          rawResult.imdbSearchMovieResults.map((searchResult: any) => {
            return {
              title: searchResult.Title,
              cover: searchResult.Poster,
              id: searchResult.imdbID,
            };
          });
        return {
          itunesSearchMovieResults: processedItunesSearchResults,
          imdbSearchMovieResults: processedImdbSearchResults,
        };
      },
    }),

    // getMediaData: builder.query<any, any | void>({
    //   query: (object) =>
    //     `movies/get_media_data/?itunes_movie_id=${object.selectedItunesResult}&imdb_movie_id=${object.selectedImdbResult}&country=${object.selectedCountry}`,
    //   // transformResponse: (rawResult: {
    //   //   itunesSearchMovieResults: any;
    //   //   imdbSearchMovieResults: any;
    //   // }) => {
    //   //   const processedItunesSearchResults = rawResult.itunesSearchMovieResults.map((searchResult: any) => {
    //   //     return {
    //   //       title: searchResult.trackName,
    //   //       cover: searchResult.artworkUrl100,
    //   //       id: searchResult.trackId,
    //   //     }
    //   //   })
    //   //   console.log("processedItunesSearchResults:", processedItunesSearchResults)
    //   //   const processedImdbSearchResults = rawResult.imdbSearchMovieResults.map((searchResult: any) => {
    //   //     return {
    //   //       title: searchResult.Title,
    //   //       cover: searchResult.Poster,
    //   //       id: searchResult.imdbID,
    //   //     }
    //   //   })
    //   //   return {
    //   //     itunesSearchMovieResults: processedItunesSearchResults,
    //   //     imdbSearchMovieResults: processedImdbSearchResults,
    //   //   };
    //   // },
    // }),

    getMediaData: builder.query<any, any | void>({
      queryFn: async (object, api, _extraOptions, baseQuery) => {
        // const state = api.getState() as RootState;
        try {
          const result: any = await baseQuery({
            url: `movies/get_media_data/?itunes_movie_id=${object.selectedItunesResult}&imdb_movie_id=${object.selectedImdbResult}&itunes_country=${object.selectedItunesCountry}`,
          });
          if (result?.error) {
            throw result.error;
          }
          const data = result.data;

          const generalMovieData = {
            title: data.movieData.title,
            originalTitle: data.movieData.originalTitle,
            itunesTitle: data.movieData.itunesTitle,
            shortDescription: data.movieData.shortDescription,
            description: data.movieData.description,
            releaseDate: data.movieData.releaseDate,
            studio: data.movieData.studio,
            rating: data.movieData.rating,
            copyright: data.movieData.copyright,
            quality: data.movieData.quality,
            format: data.movieData.format,
            source: data.movieData.source,
            sourceLink: data.movieData.sourceLink,
            itunesId: data.movieData.itunesId,
            imdbId: data.movieData.imdbId,
            imdbRating: data.movieData.imdbRating,
            // filePath: data.movieData.filePath,
            trailerUrl: data.movieData.trailerUrl,
            // comments: data.movieData.comments,
          };

          const genreData = data.movieData.genres.map((genreItem: any) => {
            return {
              genre: {
                id: genreItem.id,
                label: genreItem.label,
              },
              main: genreItem.main,
            };
          });

          const countryData = data.movieData.countries.map(
            (countryItem: any) => {
              return {
                country: {
                  id: countryItem.id,
                  label: countryItem.label,
                },
                main: countryItem.main,
              };
            },
          );

          const secondaryMovieData = {
            genres: genreData,
            countries: countryData,
            tags: data.movieData.tags,
            collections: data.movieData.collections,
          };

          const castCrewMovieData = {
            cast: data.movieData.cast,
            directors: data.movieData.directors,
            producers: data.movieData.producers,
            screenwriters: data.movieData.screenwriters,
          };

          // const languagesMovieData = data.movieData.languages.map((languageItem: any) => {
          //   return {
          //     language: {
          //       id: languageItem.id,
          //       label: languageItem.label,
          //     },
          //     main: languageItem.main,
          //   };
          // });

          const languageData = data.movieData.languages.map(
            (languageItem: any) => {
              return {
                language: {
                  id: languageItem.id,
                  label: languageItem.label,
                },
                main: languageItem.main,
                audioTrack: false,
                subtitle: false,
              };
            },
          );

          const languagesMovieData = {
            languages: languageData,
          };

          const movieData = {
            generalMovieData: generalMovieData,
            secondaryMovieData: secondaryMovieData,
            castCrewMovieData: castCrewMovieData,
            languagesMovieData: languagesMovieData,
            studios: data.studios,
            ratings: data.ratings,
            qualities: data.qualities,
            formats: data.formats,
            sources: data.sources,

            genres: data.genres,
            countries: data.countries,
            tags: data.tags,
            collections: data.collections,

            languages: data.languages,

            accessLevels: data.accessLevels,
          };

          api.dispatch(setMovieData(movieData));

          // "genres": new_genres,
          // "languages": new_languages,
          // "countries": new_countries,
          // "tags": [{}],
          // "collections": [{}],
          // # "runtime": 0,
          // "cast": cast,
          // "directors": directors,
          // "screenwriters": screenwriters,
          // "producers": producers,
          // "editing_completed": False,

          // const usageParameters = state.config.usageParameters;
          // const { currency, amount, concept, externalId, bankCodes } = data;
          // const newUsageParameters = {
          //   ...usageParameters,
          //   currency,
          //   amount,
          //   concept,
          //   externalId,
          //   bankCodes,
          // } as IUsageParameters;
          // api.dispatch(setUsageParameters(newUsageParameters));
          // const countries = state.config.countries;
          // if (countries.length === 1) {
          //   const filteredBanks = filterBanks(countries[0], bankCodes);
          //   if (!filteredBanks) {
          //     throw { status: 'invalidBanks', error: 'Invalid banks' };
          //   } else {
          //     const newCountryData = { ...countries[0] };
          //     newCountryData.banks = filteredBanks;
          //     api.dispatch(setCountries([newCountryData]));
          //   }
          // }
          return { data: data };
        } catch (errorData: any) {
          // `onError` side-effect
          // console.log('ERROR ENTERED');
          return { error: errorData };
        }
      },
    }),

    createMovie: builder.mutation({
      query: (data) => ({
        url: `movie/movies/`,
        method: 'POST',
        body: data,
        // headers: {
        //   'Content-Type': 'multipart/form-data;'
        // },
        // formData: true,
      }),
    }),

    // patchMovie: builder.mutation({
    //   query: ({ id, ...data }) => ({
    //     url: `movie/movies/${id}/`,
    //     method: 'PATCH',
    //     body: data,
    //     // headers: {
    //     //   'Content-Type': 'multipart/form-data;'
    //     // },
    //     // formData: true,
    //   }),
    // }),

    patchMovie: builder.mutation<any | any, any>({
      queryFn: async (data, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootState;
        const newMovieId = state.addMedia.newMovieId;
        const currentMovieStep = state.addMedia.currentMovieStep;
        let formData = new FormData();
        // console.log('PRE DATA:', data);
        formData.append('step', data.step);
        if (currentMovieStep === 7) {
          formData.append('cover', data.cover);
          formData.append('background', data.background);
        } else if (currentMovieStep === 8) {
          // formData.append('languages', data.languages)

          for (let i = 0; i < data.languages.length; i++) {
            formData.append('subtitlesSrt', data.languages[i]?.subtitleSrt);
          }

          for (let i = 0; i < data.languages.length; i++) {
            formData.append('subtitlesVtt', data.languages[i]?.subtitleVtt);
          }

          // formData.delete("languages")
          for (let i = 0; i < data.languages.length; i++) {
            data.languages[i].subtitleSrt =
              data.languages[i]?.subtitleSrt?.name;
            data.languages[i].subtitleVtt =
              data.languages[i]?.subtitleVtt?.name;
          }
          formData.append('languages', JSON.stringify(data.languages));
        } else if (currentMovieStep === 9) {
          formData.append('chaptersFile', data.chaptersFile);
          formData.append('hasNamedChapters', data.hasNamedChapters);
        } else if (currentMovieStep === 10) {
          formData = data;
        }

        try {
          const result: any = await baseQuery({
            url: `movie/movies/${newMovieId}/`,
            method: 'PATCH',
            body: formData,
          });
          if (result?.error) {
            throw result.error;
          }
          const data = result.data;
          return { data: data };
        } catch (errorData: any) {
          // `onError` side-effect
          return { error: errorData };
        }
      },
    }),

    getMovieOptions: builder.query<any, any | void>({
      query: (object) => `movies/?keyword=${object.movieTitle}`,
      transformResponse: (rawResult: { results: any }) => {
        const processedMovieSearchResults = rawResult.results.map(
          (searchResult: any) => {
            return {
              title: searchResult.title,
              cover: searchResult.cover,
              id: searchResult.id,
              imdbId: searchResult.imdbId,
            };
          },
        );
        // console.log(
        //   'processedMovieSearchResults:',
        //   processedMovieSearchResults,
        // );
        return {
          movieSearchResults: processedMovieSearchResults,
        };
      },
    }),

    // createMovie: builder.mutation<any | any, any>({
    //   queryFn: async (data, api, _extraOptions, baseQuery) => {
    //     const state = api.getState() as RootState;

    //     const { generalMovieData, secondaryMovieData, castCrewMovieData, imagesMovieData, languagesMovieData, chaptersMovieData } = state.addMedia;
    //     // const fullMovieData = { ...generalMovieData, ...secondaryMovieData, ...castCrewMovieData, ...imagesMovieData, ...languagesMovieData, ...chaptersMovieData, ...data, mode: "new"}

    //     const fullMovieData = { ...generalMovieData, ...secondaryMovieData, ...data, mode: "new"}

    //     console.log("FULL MOVIE DATA:", fullMovieData)
    //     const formData = new FormData()
    //     // formData.append('background', data.background)
    //     // formData.append('title', fullMovieData.title)
    //     // fileFormData.append("background", fullMovieData.background);

    //     for (const [key, value] of Object.entries(fullMovieData)) {
    //       // fileFormData.append(key, JSON.stringify(value));
    //       formData.append(key, JSON.stringify(value));
    //     }

    //     formData.delete("chaptersFile")
    //     formData.append("chaptersFile", fullMovieData.chaptersFile);

    //     formData.delete("poster")
    //     formData.append("poster", fullMovieData);

    //     formData.delete("background")
    //     formData.append("background", fullMovieData.background);

    //     for (let i = 0 ; i < fullMovieData.languages.length ; i++) {
    //       formData.append("subtitlesSrt", fullMovieData.languages[i]?.subtitleSrt);
    //     }

    //     for (let i = 0 ; i < fullMovieData.languages.length ; i++) {
    //       formData.append("subtitlesVtt", fullMovieData.languages[i]?.subtitleVtt);
    //     }

    //     formData.delete("languages")
    //     for (let i = 0 ; i < fullMovieData.languages.length ; i++) {
    //       fullMovieData.languages[i].subtitleFileSrt = fullMovieData.languages[i]?.subtitleFileSrt?.name
    //       fullMovieData.languages[i].subtitleFileVtt = fullMovieData.languages[i]?.subtitleFileVtt?.name
    //     }
    //     formData.append("languages", JSON.stringify(fullMovieData.languages))

    //     // fileFormData.append("background", fullMovieData.background);

    //     console.log("FILE FORM DATA:", formData)

    //     try {
    //       const result: any = await baseQuery({
    //         url: `movie/movies/`,
    //         method: 'POST',
    //         body: formData,
    //         // data: fileFormData,
    //     //     formData: true,
    //     //             headers: {
    //     //   'Content-Type': 'multipart/form-data;'
    //     // },
    //       });
    //       if (result?.error) {
    //         throw result.error;
    //       }
    //       const data = result.data;
    //       return { data: data };
    //     } catch (errorData: any) {
    //       // `onError` side-effect
    //       return { error: errorData };
    //     }
    //   },
    // }),

    getMovieUser: builder.query<any, string | undefined>({
      query: (id) => `movie/movie_users/${id}/`,
      // providesTags: ['Movie'],
      providesTags: (result, error, arg) => [{ type: 'MovieUser', id: arg }],
    }),

    saveMovieUser: builder.mutation({
      query: (data) => ({
        url: `movie/movie_users/`,
        method: 'POST',
        body: data,
        // headers: {
        //   'Content-Type': 'multipart/form-data;'
        // },
        // formData: true,
      }),
      // invalidatesTags: (result, error, arg) => [{ type: 'MovieUser' }],
      invalidatesTags: () => [{ type: 'MovieUser' }],
    }),
  }),
});

export const {
  useGetMoviesQuery,
  useGetMovieQuery,
  useGetRandomMovieQuery,
  useGetItunesAndImdbMovieOptionsQuery,
  useGetMediaDataQuery,
  useCreateMovieMutation,
  usePatchMovieMutation,
  useGetMovieOptionsQuery,
  useGetMovieUserQuery,
  useSaveMovieUserMutation,
} = extendedApiSlice;

import { setAutographOrderData } from '../slices/addAutographOrderSlice';
import {
  setAutographArtistsData,
  setAutographData,
} from '../slices/addAutographSlice';
import { RootState } from '../store';
import { mediaManagerServerApi } from './mediaManagerServerApi';

export const extendedApiSlice = mediaManagerServerApi.injectEndpoints({
  endpoints: (builder) => ({
    getAutographs: builder.query<any, any | void>({
      query: (object) =>
        `autographs/?page=${object?.page || 1}&mode=${object?.mode || ''}&keyword=${object?.keyword || ''}&tag=${object?.tag || ''}&collection=${object?.collection || ''}`, //&page_size=12

      transformResponse: (rawResult: {
        results: any;
        currentPage: number;
        totalPages: number;
      }) => {
        // return rawResult.results;
        return {
          results: rawResult.results,
          currentPage: rawResult.currentPage,
          totalPages: rawResult.totalPages,
        };
      },
      // providesTags: ['Autograph'],
      // providesTags: (result = [], error, arg) => [
      // providesTags: (result = {}) => [
      //   'Autograph',
      //   ...result.results.map(({ id }: { id: number }) => ({
      //     type: 'Autograph',
      //     id,
      //   })),
      // ],

      // providesTags: (result, error, arg) =>
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }: { id: number }) => ({
                type: 'Autograph' as const,
                id,
              })),
              'Autograph',
            ]
          : ['Autograph'],
      serializeQueryArgs: ({ queryArgs }) => {
        if (queryArgs) {
          const { page, ...otherArgs } = queryArgs;
          page;
          // console.log('PAGE:', page);
          return otherArgs;
        } else {
          return queryArgs;
        }
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        //#1
        currentCache.results.push(...newItems.results);
        currentCache.currentPage = newItems.currentPage;
        //#2
        // if (newItems.currentPage > currentCache.currentPage) {
        //   currentCache.results.push(...newItems.results);
        //   currentCache.currentPage = newItems.currentPage;
        // } else {
        //   currentCache.results = newItems.results;
        //   currentCache.currentPage = newItems.currentPage;
        // }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        //#1
        return currentArg?.page > previousArg?.page;
        //#2
        // return currentArg?.page !== previousArg?.page;
      },
    }),
    getAutograph: builder.query<any, string | undefined>({
      query: (id) => `autographs/${id}/`,
      // providesTags: ['Autograph'],
      // providesTags: (result, error, arg) => [{ type: 'Autograph', id: arg }]
      providesTags: (result, error, arg) => [{ type: 'Autograph', id: arg }],
    }),

    getRandomAutograph: builder.query<any, any | void>({
      query: (object) => `autographs/get_random_autograph/?type=${object?.type || ''}&mode=${object?.mode || ''}`,
      providesTags: (result, error, arg) => [{ type: 'Autograph', id: arg }],
    }),

    getAutographOrderMediaData: builder.query<any, any | void>({
      queryFn: async (object, api, _extraOptions, baseQuery) => {
        try {
          const result: any = await baseQuery({
            url: `autograph/orders/get_media_data`,
          });
          if (result?.error) {
            throw result.error;
          }
          const data = result.data;
          const autographOrderData = {
            sellers: data.sellers,
            stores: data.stores,
            documentTypes: data.documentTypes,
            imageTypes: data.imageTypes,
          };

          api.dispatch(setAutographOrderData(autographOrderData));

          return { data: data };
        } catch (errorData: any) {
          // `onError` side-effect
          // console.log('ERROR ENTERED');
          return { error: errorData };
        }
      },
    }),
    createAutographOrder: builder.mutation({
      query: (data) => ({
        url: `autograph/orders/`,
        method: 'POST',
        body: data,
        // headers: {
        //   'Content-Type': 'multipart/form-data;'
        // },
        // formData: true,
      }),
    }),
    patchAutographOrder: builder.mutation<any | any, any>({
      queryFn: async (data, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootState;
        const newAutographOrderId =
          state.addAutographOrder.newAutographOrderId;
        const currentAutographOrderStep =
          state.addAutographOrder.currentAutographOrderStep;
        const formData = new FormData();
        // console.log('PRE DATA:', data);
        formData.append('step', data.step);
        if (currentAutographOrderStep === 2) {
          for (let i = 0; i < data.documents.length; i++) {
            formData.append('files', data.documents[i]?.file);
          }
          for (let i = 0; i < data.documents.length; i++) {
            data.documents[i].file = data.documents[i]?.file?.name;
          }
          formData.append('documents', JSON.stringify(data.documents));
        } else if (currentAutographOrderStep === 3) {
          for (let i = 0; i < data.images.length; i++) {
            formData.append('files', data.images[i]?.file);
          }
          for (let i = 0; i < data.images.length; i++) {
            data.images[i].file = data.images[i]?.file?.name;
          }
          formData.append('images', JSON.stringify(data.images));
        }
        // else if (currentMovieStep === 9) {
        //   formData.append('chaptersFile', data.chaptersFile);
        //   formData.append('hasNamedChapters', data.hasNamedChapters);
        // } else if (currentMovieStep === 10) {
        //   formData = data;
        // }

        try {
          const result: any = await baseQuery({
            url: `autograph/orders/${newAutographOrderId}/`,
            method: 'PATCH',
            body: formData,
          });
          if (result?.error) {
            throw result.error;
          }
          const data = result.data;
          return { data: data };
        } catch (errorData: any) {
          // `onError` side-effect
          return { error: errorData };
        }
      },
    }),

    getAutographOrder: builder.query<any, string | undefined>({
      query: (id) => `autograph/orders/${id}/`,
      // providesTags: ['Autograph'],
      // providesTags: (result, error, arg) => [{ type: 'Autograph', id: arg }]
      providesTags: (result, error, arg) => [{ type: 'Order', id: arg }],
    }),

    getAutographMediaData: builder.query<any, any | void>({
      queryFn: async (object, api, _extraOptions, baseQuery) => {
        try {
          const result: any = await baseQuery({
            url: `autograph/autographs/get_media_data`,
          });
          if (result?.error) {
            throw result.error;
          }
          const data = result.data;
          const autographData = {
            currencies: data.currencies,
            sizes: data.sizes,
            orientations: data.orientations,
            documentTypes: data.documentTypes,
            imageTypes: data.imageTypes,
            videoTypes: data.videoTypes,
            authenticators: data.authenticators,
            tags: data.tags,
            collections: data.collections,
            accessLevels: data.accessLevels,
          };

          api.dispatch(setAutographData(autographData));

          return { data: data };
        } catch (errorData: any) {
          // `onError` side-effect
          // console.log('ERROR ENTERED');
          return { error: errorData };
        }
      },
    }),
    createAutograph: builder.mutation({
      query: (data) => ({
        url: `autograph/autographs/`,
        method: 'POST',
        body: data,
        // headers: {
        //   'Content-Type': 'multipart/form-data;'
        // },
        // formData: true,
      }),
    }),
    patchAutograph: builder.mutation<any | any, any>({
      queryFn: async (data, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootState;
        const newAutographId = state.addAutograph.newAutographId;
        const currentAutographStep = state.addAutograph.currentAutographStep;
        let formData = new FormData();
        // console.log('PRE DATA:', data);
        formData.append('step', data.step);
        if (
          currentAutographStep === 7 ||
          currentAutographStep === 9 ||
          currentAutographStep === 16
        ) {
          formData = data;
        } else if (currentAutographStep === 10) {
          for (let i = 0; i < data.documents.length; i++) {
            formData.append('files', data.documents[i]?.file);
          }
          for (let i = 0; i < data.documents.length; i++) {
            data.documents[i].file = data.documents[i]?.file?.name;
          }
          formData.append('documents', JSON.stringify(data.documents));
          formData.append('authenticatorId', data.authenticatorId);
        } else if (currentAutographStep === 11) {
          for (let i = 0; i < data.images.length; i++) {
            formData.append('files', data.images[i]?.file);
          }
          for (let i = 0; i < data.images.length; i++) {
            data.images[i].file = data.images[i]?.file?.name;
          }
          formData.append('images', JSON.stringify(data.images));
          formData.append('authenticatorId', data.authenticatorId);
        } else if (currentAutographStep === 13) {
          for (let i = 0; i < data.documents.length; i++) {
            formData.append('files', data.documents[i]?.file);
          }
          for (let i = 0; i < data.documents.length; i++) {
            data.documents[i].file = data.documents[i]?.file?.name;
          }
          formData.append('documents', JSON.stringify(data.documents));
        } else if (currentAutographStep === 14) {
          for (let i = 0; i < data.images.length; i++) {
            formData.append('files', data.images[i]?.file);
          }
          for (let i = 0; i < data.images.length; i++) {
            data.images[i].file = data.images[i]?.file?.name;
          }
          formData.append('images', JSON.stringify(data.images));
        } else if (currentAutographStep === 15) {
          for (let i = 0; i < data.videos.length; i++) {
            formData.append('files', data.videos[i]?.file);
          }
          for (let i = 0; i < data.videos.length; i++) {
            data.videos[i].file = data.videos[i]?.file?.name;
          }
          formData.append('videos', JSON.stringify(data.videos));
        }
        // else if (currentMovieStep === 9) {
        //   formData.append('chaptersFile', data.chaptersFile);
        //   formData.append('hasNamedChapters', data.hasNamedChapters);
        // } else if (currentMovieStep === 10) {
        //   formData = data;
        // }

        try {
          const result: any = await baseQuery({
            url: `autograph/autographs/${newAutographId}/`,
            method: 'PATCH',
            body: formData,
          });
          if (result?.error) {
            throw result.error;
          }
          const data = result.data;
          return { data: data };
        } catch (errorData: any) {
          // `onError` side-effect
          return { error: errorData };
        }
      },
    }),

    getAutographArtistsData: builder.query<any, any | void>({
      queryFn: async (object, api, _extraOptions, baseQuery) => {
        // const state = api.getState() as RootState;
        const state = api.getState() as RootState;
        const newAutographId = state.addAutograph.newAutographId;
        try {
          const result: any = await baseQuery({
            url: `autographs/get_autograph_artists_data/?autograph_id=${newAutographId}&movie_id=${object.selectedMovieResult?.id}&tv_show_id=${object.selectedTvShowResult?.id}`,
          });
          if (result?.error) {
            throw result.error;
          }
          const data = result.data;

          api.dispatch(
            setAutographArtistsData({ artists: data.autographArtistsData }),
          );

          return { data: data };
        } catch (errorData: any) {
          // `onError` side-effect
          // console.log('ERROR ENTERED');
          return { error: errorData };
        }
      },
    }),
  }),
});

export const {
  useGetAutographsQuery,
  useGetAutographQuery,
  useGetRandomAutographQuery,
  useGetAutographOrderMediaDataQuery,
  useCreateAutographOrderMutation,
  usePatchAutographOrderMutation,
  useGetAutographMediaDataQuery,
  useCreateAutographMutation,
  usePatchAutographMutation,
  useGetAutographOrderQuery,
  useGetAutographArtistsDataQuery,
} = extendedApiSlice;

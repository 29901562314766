import {
  setSelectedTvShowSeasonEpisodeData,
  setTvShowSeasonEpisodesData,
} from '../slices/addTvShowSeasonEpisodeSlice';
import { setTvShowSeasonData } from '../slices/addTvShowSeasonSlice';
import { setTvShowData } from '../slices/addTvShowSlice';
import { RootState } from '../store';
import { mediaManagerServerApi } from './mediaManagerServerApi';

export const extendedApiSlice = mediaManagerServerApi.injectEndpoints({
  endpoints: (builder) => ({
    getTVShows: builder.query<any, any | void>({
      query: (object) =>
        `tv-shows/?page=${object?.page || 1}&mode=${object?.mode || ''}&keyword=${object?.keyword || ''}&tag=${object?.tag || ''}&collection=${object?.collection || ''}`, //&page_size=12

      transformResponse: (rawResult: {
        results: any;
        currentPage: number;
        totalPages: number;
      }) => {
        // return rawResult.results;
        return {
          results: rawResult.results,
          currentPage: rawResult.currentPage,
          totalPages: rawResult.totalPages,
        };
      },
      // providesTags: ['TVShow'],
      // providesTags: (result = [], error, arg) => [
      // providesTags: (result = {}) => [
      //   'TVShow',
      //   ...result.results.map(({ id }: { id: number }) => ({
      //     type: 'TVShow',
      //     id,
      //   })),
      // ],

      // providesTags: (result, error, arg) =>
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }: { id: number }) => ({
                type: 'TVShow' as const,
                id,
              })),
              'TVShow',
            ]
          : ['TVShow'],

      serializeQueryArgs: ({ queryArgs }) => {
        if (queryArgs) {
          const { page, ...otherArgs } = queryArgs;
          page;
          // console.log('PAGE:', page);
          return otherArgs;
        } else {
          return queryArgs;
        }
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        //#1
        currentCache.results.push(...newItems.results);
        currentCache.currentPage = newItems.currentPage;
        //#2
        // if (newItems.currentPage > currentCache.currentPage) {
        //   currentCache.results.push(...newItems.results);
        //   currentCache.currentPage = newItems.currentPage;
        // } else {
        //   currentCache.results = newItems.results;
        //   currentCache.currentPage = newItems.currentPage;
        // }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        //#1
        return currentArg?.page > previousArg?.page;
        //#2
        // return currentArg?.page !== previousArg?.page;
      },
    }),
    getTVShow: builder.query<any, string | undefined>({
      query: (id) => `tv-shows/${id}/`,
      // providesTags: ['TVShow'],
      providesTags: (result, error, arg) => [{ type: 'TVShow', id: arg }],
    }),

    getTVShowSeason: builder.query<any, string | undefined>({
      query: (id) => `seasons/${id}/`,
      // providesTags: ['TVShowSeason'],
      providesTags: (result, error, arg) => [
        { type: 'TVShowSeason', id: arg },
      ],
    }),

    getTVShowSeasonEpisode: builder.query<any, string | undefined>({
      query: (id) => `episodes/${id}/`,
      // providesTags: ['TVShowSeasonEpisode'],
      providesTags: (result, error, arg) => [
        { type: 'TVShowSeasonEpisode', id: arg },
      ],
    }),

    getItunesAndImdbTvOptions: builder.query<any, any | void>({
      query: (object) =>
        `tv-shows/get_itunes_and_imdb_options/?tv_show_title=${object.tvShowTitle}&itunes_country=${object?.itunesCountry ? object.itunesCountry.value : null}`,
      transformResponse: (rawResult: {
        itunesSearchResults: any;
        imdbSearchResults: any;
      }) => {
        const processedItunesSearchResults = rawResult.itunesSearchResults.map(
          (searchResult: any) => {
            return {
              title: searchResult.collectionName,
              cover: searchResult.artworkUrl100.replace(
                '100x100bb',
                '100000x100000-999',
              ),
              id: searchResult.collectionId,
            };
          },
        );
        // console.log(
        //   'processedItunesSearchResults:',
        //   processedItunesSearchResults,
        // );
        const processedImdbSearchResults = rawResult.imdbSearchResults.map(
          (searchResult: any) => {
            return {
              title: searchResult.Title,
              cover: searchResult.Poster,
              id: searchResult.imdbID,
            };
          },
        );
        return {
          itunesSearchMovieResults: processedItunesSearchResults,
          imdbSearchMovieResults: processedImdbSearchResults,
        };
      },
    }),

    getTvMediaData: builder.query<any, any | void>({
      queryFn: async (object, api, _extraOptions, baseQuery) => {
        // const state = api.getState() as RootState;
        try {
          const result: any = await baseQuery({
            url: `tv-shows/get_media_data/?itunes_tv_show_id=${object.selectedItunesResult}&imdb_tv_show_id=${object.selectedImdbResult}&itunes_country=${object.selectedItunesCountry}`,
          });
          if (result?.error) {
            throw result.error;
          }
          const data = result.data;

          const generalTvShowData = {
            title: data.tvShowData.title,
            originalTitle: data.tvShowData.originalTitle,
            shortDescription: data.tvShowData.shortDescription,
            description: data.tvShowData.description,
            startYear: data.tvShowData.startYear,
            endYear: data.tvShowData.endYear,
            imdbId: data.tvShowData.imdbId,
            imdbRating: data.tvShowData.imdbRating,
            rating: data.tvShowData.rating,
            // network: data.tvShowData.network,
          };

          const genreData = data.tvShowData.genres.map((genreItem: any) => {
            return {
              genre: {
                id: genreItem.id,
                label: genreItem.label,
              },
              main: genreItem.main,
            };
          });

          const countryData = data.tvShowData.countries.map(
            (countryItem: any) => {
              return {
                country: {
                  id: countryItem.id,
                  label: countryItem.label,
                },
                main: countryItem.main,
              };
            },
          );

          const secondaryTvShowData = {
            genres: genreData,
            countries: countryData,
            tags: data.tvShowData.tags,
            collections: data.tvShowData.collections,
          };

          // const castCrewTvShowSeasonEpisodeData = {
          //   cast: data.tvShowSeasonEpisodeData.cast,
          //   directors: data.tvShowSeasonEpisodeData.directors,
          //   producers: data.tvShowSeasonEpisodeData.producers,
          //   screenwriters: data.tvShowSeasonEpisodeData.screenwriters,
          // };

          // const languagesTvShowSeasonEpisodeData = data.tvShowSeasonEpisodeData.languages.map((languageItem: any) => {
          //   return {
          //     language: {
          //       id: languageItem.id,
          //       label: languageItem.label,
          //     },
          //     main: languageItem.main,
          //   };
          // });

          // const languageData = data.tvShowSeasonEpisodeData.languages.map(
          //   (languageItem: any) => {
          //     return {
          //       language: {
          //         id: languageItem.id,
          //         label: languageItem.label,
          //       },
          //       main: languageItem.main,
          //       audioTrack: false,
          //       subtitle: false,
          //     };
          //   },
          // );

          // const languagesTvShowSeasonEpisodeData = {
          //   languages: languageData,
          // };

          const tvShowData = {
            generalTvShowData: generalTvShowData,
            secondaryTvShowData: secondaryTvShowData,
            // castCrewMovieData: castCrewMovieData,
            // languagesMovieData: languagesMovieData,
            // studios: data.studios,
            ratings: data.ratings,
            // qualities: data.qualities,
            // formats: data.formats,
            // sources: data.sources,
            // networks: data.networks,

            genres: data.genres,
            countries: data.countries,
            tags: data.tags,
            collections: data.collections,

            // languages: data.languages,

            accessLevels: data.accessLevels,
          };

          api.dispatch(setTvShowData(tvShowData));

          // "genres": new_genres,
          // "languages": new_languages,
          // "countries": new_countries,
          // "tags": [{}],
          // "collections": [{}],
          // # "runtime": 0,
          // "cast": cast,
          // "directors": directors,
          // "screenwriters": screenwriters,
          // "producers": producers,
          // "editing_completed": False,

          // const usageParameters = state.config.usageParameters;
          // const { currency, amount, concept, externalId, bankCodes } = data;
          // const newUsageParameters = {
          //   ...usageParameters,
          //   currency,
          //   amount,
          //   concept,
          //   externalId,
          //   bankCodes,
          // } as IUsageParameters;
          // api.dispatch(setUsageParameters(newUsageParameters));
          // const countries = state.config.countries;
          // if (countries.length === 1) {
          //   const filteredBanks = filterBanks(countries[0], bankCodes);
          //   if (!filteredBanks) {
          //     throw { status: 'invalidBanks', error: 'Invalid banks' };
          //   } else {
          //     const newCountryData = { ...countries[0] };
          //     newCountryData.banks = filteredBanks;
          //     api.dispatch(setCountries([newCountryData]));
          //   }
          // }
          return { data: data };
        } catch (errorData: any) {
          // `onError` side-effect
          // console.log('ERROR ENTERED');
          return { error: errorData };
        }
      },
    }),

    createTvShow: builder.mutation({
      query: (data) => ({
        url: `tv_show/tv-shows/`,
        method: 'POST',
        body: data,
        // headers: {
        //   'Content-Type': 'multipart/form-data;'
        // },
        // formData: true,
      }),
    }),

    patchTvShow: builder.mutation<any | any, any>({
      queryFn: async (data, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootState;
        const newTvShowId = state.addTvShow.newTvShowId;
        const currentStep = state.addTvShow.currentStep;
        let formData = new FormData();
        // console.log('PRE DATA:', data);
        formData.append('step', data.step);
        if (currentStep === 5) {
          formData.append('cover', data.cover);
          formData.append('background', data.background);
        } else if (currentStep === 6) {
          formData = data;
        }

        try {
          const result: any = await baseQuery({
            url: `tv_show/tv-shows/${newTvShowId}/`,
            method: 'PATCH',
            body: formData,
          });
          if (result?.error) {
            throw result.error;
          }
          const data = result.data;
          return { data: data };
        } catch (errorData: any) {
          // `onError` side-effect
          return { error: errorData };
        }
      },
    }),

    getTvShowOptions: builder.query<any, any | void>({
      query: (object) => `tv-shows/?keyword=${object.tvShowTitle}`,
      transformResponse: (rawResult: { results: any }) => {
        const processedTvShowSearchResults = rawResult.results.map(
          (searchResult: any) => {
            return {
              title: searchResult.title,
              cover: searchResult.cover,
              id: searchResult.id,
              imdbId: searchResult.imdbId,
            };
          },
        );
        // console.log(
        //   'processedTvShowSearchResults:',
        //   processedTvShowSearchResults,
        // );
        return {
          tvShowSearchResults: processedTvShowSearchResults,
        };
      },
    }),

    getItunesTvShowSeasonOptions: builder.query<any, any | void>({
      query: (object) =>
        `seasons/get_itunes_options/?tv_show_title=${object.tvShowTitle}&itunes_country=${object?.itunesCountry ? object.itunesCountry.value : null}`,
      transformResponse: (rawResult: { itunesSearchResults: any }) => {
        const processedItunesSearchResults = rawResult.itunesSearchResults.map(
          (searchResult: any) => {
            return {
              title: searchResult.collectionName,
              cover: searchResult.artworkUrl100.replace(
                '100x100bb',
                '100000x100000-999',
              ),
              id: searchResult.collectionId,
            };
          },
        );
        // console.log(
        //   'processedItunesSearchResults:',
        //   processedItunesSearchResults,
        // );
        return {
          itunesSearchTvShowSeasonResults: processedItunesSearchResults,
        };
      },
    }),

    getTvSeasonMediaData: builder.query<any, any | void>({
      queryFn: async (object, api, _extraOptions, baseQuery) => {
        // const state = api.getState() as RootState;
        try {
          const result: any = await baseQuery({
            url: `tv_show/seasons/get_media_data/?itunes_id=${object.selectedItunesResult}&imdb_id=${object.selectedImdbResult}&itunes_country=${object.selectedItunesCountry}&season=${object.season}`,
          });
          if (result?.error) {
            throw result.error;
          }
          const data = result.data;

          const generalTvShowSeasonData = {
            title: data.tvShowSeasonData.title,
            seasonNumber: data.tvShowSeasonData.seasonNumber,
            shortDescription: data.tvShowSeasonData.shortDescription,
            description: data.tvShowSeasonData.description,
            copyright: data.tvShowSeasonData.copyright,
            network: data.tvShowSeasonData.network,
            itunesId: data.tvShowSeasonData.itunesId,
            trailerUrl: data.tvShowSeasonData.trailerUrl,
          };

          const castCrewTvShowSeasonData = {
            cast: data.tvShowSeasonData.cast,
            producers: data.tvShowSeasonData.producers,
          };

          const tvShowSeasonData = {
            generalTvShowSeasonData: generalTvShowSeasonData,
            castCrewTvShowSeasonData: castCrewTvShowSeasonData,
            networks: data.networks,
          };

          api.dispatch(setTvShowSeasonData(tvShowSeasonData));

          return { data: data };
        } catch (errorData: any) {
          // `onError` side-effect
          // console.log('ERROR ENTERED');
          return { error: errorData };
        }
      },
    }),

    createTvShowSeason: builder.mutation({
      query: (data) => ({
        url: `tv_show/seasons/`,
        method: 'POST',
        body: data,
        // headers: {
        //   'Content-Type': 'multipart/form-data;'
        // },
        // formData: true,
      }),
    }),

    patchTvShowSeason: builder.mutation<any | any, any>({
      queryFn: async (data, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootState;
        const newTvShowSeasonId = state.addTvShowSeason.newTvShowSeasonId;
        const currentStep = state.addTvShowSeason.currentTvShowSeasonStep;
        const formData = new FormData();
        // console.log('PRE DATA:', data);
        formData.append('step', data.step);
        if (currentStep === 6) {
          formData.append('cover', data.cover);
          formData.append('background', data.background);
        }

        try {
          const result: any = await baseQuery({
            url: `tv_show/seasons/${newTvShowSeasonId}/`,
            method: 'PATCH',
            body: formData,
          });
          if (result?.error) {
            throw result.error;
          }
          const data = result.data;
          return { data: data };
        } catch (errorData: any) {
          // `onError` side-effect
          return { error: errorData };
        }
      },
    }),

    getTvShowSeasonOptions: builder.query<any, any | void>({
      query: (object) => `seasons/?keyword=${object.tvShowTitle}`,
      transformResponse: (rawResult: { results: any }) => {
        const processedTvShowSeasonSearchResults = rawResult.results.map(
          (searchResult: any) => {
            return {
              title: `${searchResult.tvShowTitle}, Season ${searchResult.number}`,
              cover: searchResult.cover,
              id: searchResult.id,
              // imdbId: searchResult.imdbId,
            };
          },
        );
        // console.log(
        //   'processedTvShowSeasonSearchResults:',
        //   processedTvShowSeasonSearchResults,
        // );
        return {
          tvShowSeasonSearchResults: processedTvShowSeasonSearchResults,
        };
      },
    }),

    getTvSeasonEpisodes: builder.query<any, any | void>({
      queryFn: async (object, api, _extraOptions, baseQuery) => {
        // const state = api.getState() as RootState;
        try {
          const result: any = await baseQuery({
            url: `tv_show/episodes/get_episodes/?season_id=${object.selectedSeason}`,
          });
          if (result?.error) {
            throw result.error;
          }
          const data = result.data;

          // const generalTvShowSeasonData = {
          //   title: data.tvShowSeasonData.title,
          //   seasonNumber: data.tvShowSeasonData.seasonNumber,
          //   shortDescription: data.tvShowSeasonData.shortDescription,
          //   description: data.tvShowSeasonData.description,
          //   copyright: data.tvShowSeasonData.copyright,
          //   network: data.tvShowSeasonData.network,
          //   itunesId: data.tvShowSeasonData.itunesId,
          //   trailerUrl: data.tvShowSeasonData.trailerUrl,
          // };

          // const castCrewTvShowSeasonData = {
          //   cast: data.tvShowSeasonData.cast,
          //   producers: data.tvShowSeasonData.producers,
          // };

          // const tvShowSeasonData = {
          //   generalTvShowSeasonData: generalTvShowSeasonData,
          //   castCrewTvShowSeasonData: castCrewTvShowSeasonData,
          //   networks: data.networks,
          // };

          api.dispatch(
            setTvShowSeasonEpisodesData(data.tvShowSeasonEpisodesData),
          );

          return { data: data };
        } catch (errorData: any) {
          // `onError` side-effect
          // console.log('ERROR ENTERED');
          return { error: errorData };
        }
      },
    }),

    getRandomEpisode: builder.query<any, any | void>({
      query: (object) => `episodes/get_random_episode/?type=${object?.type || ''}&mode=${object?.mode || ''}`,
      providesTags: (result, error, arg) => [
        { type: 'TVShowSeasonEpisode', id: arg },
      ],
    }),

    getTvSeasonEpisodeMediaData: builder.query<any, any | void>({
      queryFn: async (object, api, _extraOptions, baseQuery) => {
        // const state = api.getState() as RootState;
        try {
          const result: any = await baseQuery({
            url: `tv_show/episodes/get_media_data/?season_id=${object.selectedSeason}&episode_imdb_id=${object.selectedEpisode}`,
          });
          if (result?.error) {
            throw result.error;
          }
          const data = result.data;

          const generalTvShowSeasonEpisodeData = {
            title: data.tvShowSeasonEpisodeData.title,
            originalTitle: data.tvShowSeasonEpisodeData.originalTitle,
            number: data.tvShowSeasonEpisodeData.number,
            code: data.tvShowSeasonEpisodeData.code,
            shortDescription: data.tvShowSeasonEpisodeData.shortDescription,
            description: data.tvShowSeasonEpisodeData.description,
            releaseDate: data.tvShowSeasonEpisodeData.releaseDate,
            itunesId: data.tvShowSeasonEpisodeData.itunesId,
            imdbId: data.tvShowSeasonEpisodeData.imdbId,
            imdbRating: data.tvShowSeasonEpisodeData.imdbRating,
            quality: data.tvShowSeasonEpisodeData.quality,
            format: data.tvShowSeasonEpisodeData.format,
            source: data.tvShowSeasonEpisodeData.source,
            sourceLink: data.tvShowSeasonEpisodeData.sourceLink,
            trailerUrl: data.tvShowSeasonEpisodeData.trailerUrl,
          };

          const castCrewTvShowSeasonEpisodeData = {
            cast: data.tvShowSeasonEpisodeData.cast,
            directors: data.tvShowSeasonEpisodeData.directors,
            producers: data.tvShowSeasonEpisodeData.producers,
            screenwriters: data.tvShowSeasonEpisodeData.screenwriters,
          };

          const languageData = data.tvShowSeasonEpisodeData.languages.map(
            (languageItem: any) => {
              return {
                language: {
                  id: languageItem.id,
                  label: languageItem.label,
                },
                main: languageItem.main,
                audioTrack: false,
                subtitle: false,
              };
            },
          );

          const languagesTvShowSeasonEpisodeData = {
            languages: languageData,
          };

          const tvShowSeasonEpisodeData = {
            generalTvShowSeasonEpisodeData: generalTvShowSeasonEpisodeData,
            castCrewTvShowSeasonEpisodeData: castCrewTvShowSeasonEpisodeData,
            languagesTvShowSeasonEpisodeData: languagesTvShowSeasonEpisodeData,
            qualities: data.qualities,
            formats: data.formats,
            sources: data.sources,
            languages: data.languages,
          };
          api.dispatch(
            setSelectedTvShowSeasonEpisodeData(tvShowSeasonEpisodeData),
          );
          return { data: data };
        } catch (errorData: any) {
          // `onError` side-effect
          // console.log('ERROR ENTERED');
          return { error: errorData };
        }
      },
    }),

    createTvShowSeasonEpisode: builder.mutation({
      query: (data) => ({
        url: `tv_show/episodes/`,
        method: 'POST',
        body: data,
        // headers: {
        //   'Content-Type': 'multipart/form-data;'
        // },
        // formData: true,
      }),
    }),

    patchTvShowSeasonEpisode: builder.mutation<any | any, any>({
      queryFn: async (data, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootState;
        const newTvShowSeasonEpisodeId =
          state.addTvShowSeasonEpisode.newTvShowSeasonEpisodeId;
        const currentTvShowSeasonEpisodeStep =
          state.addTvShowSeasonEpisode.currentTvShowSeasonEpisodeStep;
        let formData = new FormData();
        // console.log('PRE DATA:', data);
        formData.append('step', data.step);
        if (currentTvShowSeasonEpisodeStep === 5) {
          formData.append('cover', data.cover);
          formData.append('background', data.background);
        } else if (currentTvShowSeasonEpisodeStep === 6) {
          // formData.append('languages', data.languages)

          for (let i = 0; i < data.languages.length; i++) {
            formData.append('subtitlesSrt', data.languages[i]?.subtitleSrt);
          }

          for (let i = 0; i < data.languages.length; i++) {
            formData.append('subtitlesVtt', data.languages[i]?.subtitleVtt);
          }

          // formData.delete("languages")
          for (let i = 0; i < data.languages.length; i++) {
            data.languages[i].subtitleSrt =
              data.languages[i]?.subtitleSrt?.name;
            data.languages[i].subtitleVtt =
              data.languages[i]?.subtitleVtt?.name;
          }
          formData.append('languages', JSON.stringify(data.languages));
        } else if (currentTvShowSeasonEpisodeStep === 7) {
          formData = data;
        }

        try {
          const result: any = await baseQuery({
            url: `tv_show/episodes/${newTvShowSeasonEpisodeId}/`,
            method: 'PATCH',
            body: formData,
          });
          if (result?.error) {
            throw result.error;
          }
          const data = result.data;
          return { data: data };
        } catch (errorData: any) {
          // `onError` side-effect
          return { error: errorData };
        }
      },
    }),

    getEpisodeUser: builder.query<any, string | undefined>({
      query: (id) => `tv_show/episode_users/${id}/`,
      // providesTags: ['Movie'],
      providesTags: (result, error, arg) => [{ type: 'EpisodeUser', id: arg }],
    }),

    saveEpisodeUser: builder.mutation({
      query: (data) => ({
        url: `tv_show/episode_users/`,
        method: 'POST',
        body: data,
        // headers: {
        //   'Content-Type': 'multipart/form-data;'
        // },
        // formData: true,
      }),
      // invalidatesTags: (result, error, arg) => [{ type: 'EpisodeUser' }],
      invalidatesTags: () => [{ type: 'EpisodeUser' }],
    }),
  }),
});

export const {
  useGetTVShowsQuery,
  useGetTVShowQuery,
  useGetTVShowSeasonQuery,
  useGetTVShowSeasonEpisodeQuery,
  useGetItunesAndImdbTvOptionsQuery,
  useGetTvMediaDataQuery,
  useCreateTvShowMutation,
  usePatchTvShowMutation,
  useGetTvShowOptionsQuery,
  useGetItunesTvShowSeasonOptionsQuery,
  useGetTvSeasonMediaDataQuery,
  useCreateTvShowSeasonMutation,
  usePatchTvShowSeasonMutation,
  useGetTvShowSeasonOptionsQuery,
  useGetTvSeasonEpisodesQuery,
  useGetRandomEpisodeQuery,
  useGetTvSeasonEpisodeMediaDataQuery,
  useCreateTvShowSeasonEpisodeMutation,
  usePatchTvShowSeasonEpisodeMutation,
  useGetEpisodeUserQuery,
  useSaveEpisodeUserMutation,
} = extendedApiSlice;

// import { useEffect, useState } from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
// import { useDispatch } from 'react-redux';
import '../Detail/Detail.scss';

import {
  Button,
  OutlineButton,
  TVShowList,
  ArtistList,
  TrailerModal,
  MediaModal,
  LoadingSpinner,
} from '../../components';
import { Link } from 'react-router-dom';
import {
  useGetEpisodeUserQuery,
  useGetTVShowSeasonEpisodeQuery,
  useSaveEpisodeUserMutation,
} from '../../app/services/tvShowServerApi';
import errorIcon from '../../assets/error.svg';
import {
  getOriginalTitleFontSize,
  getOverviewFontSize,
  getTitleFontSize,
} from '../../shared/utils';
import copyIcon from '../../assets/copy-icon.svg';
import eyeIcon from '../../assets/eye-icon.svg';
import eyeFilledIcon from '../../assets/eye-filled-icon.svg';
import backgroundIcon from '../../assets/background-icon.svg';

export const TVShowEpisodeDetail = () => {
  // const { category, id, episodeID } = useParams();
  const { category, id } = useParams();
  // const dispatch = useDispatch();
  const renderSimilarItems = () => {
    return (
      <TVShowList
        category={category}
        type="similar"
        id={tvShowSeasonEpisodeItem.id}
      />
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [category, id]);

  const copyToClipboard = () => {
    const TempText = document.createElement('input');
    // TempText.value = movieLink;
    document.body.appendChild(TempText);
    TempText.select();
    document.execCommand('copy');
    document.body.removeChild(TempText);
    alert('Episode link copied to clipboard');
  };

  const {
    data: tvShowSeasonEpisodeItem = {},
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetTVShowSeasonEpisodeQuery(id);

  const {
    data: episodeUserItem = {},
    // isLoading,
    // // isFetching,
    // isError,
    // error,
  } = useGetEpisodeUserQuery(id);

  const [openTVShowSeasonEpisodeModal, setOpenTVShowSeasonEpisodeModal] =
    useState(false);
  const toggleTVShowSeasonEpisodeModal = () =>
    setOpenTVShowSeasonEpisodeModal(!openTVShowSeasonEpisodeModal);

  const [openTrailerModal, setOpenTrailerModal] = useState(false);
  const toggleTrailerModal = () => setOpenTrailerModal(!openTrailerModal);

  const [saveEpisodeUser] = useSaveEpisodeUserMutation();

  const markAsWatched = (watched: boolean) => {
    const data = { id: id, watched: watched };
    saveEpisodeUser(data);
  };

  const background = tvShowSeasonEpisodeItem.background
    ? tvShowSeasonEpisodeItem.background
    : tvShowSeasonEpisodeItem.cover
      ? tvShowSeasonEpisodeItem.cover
      : tvShowSeasonEpisodeItem.image;

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="media-slide-no-item-single">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="media-slide-no-item-single">
          <img src={errorIcon} alt="error-icon" />
          <p>
            {error?.status === 404
              ? 'Page Not Found'
              : error?.error
                ? error?.error
                : 'An error ocurred'}
          </p>
          {error?.status === 404 && (
            <Link className="media-a" to="/">
              <Button>Go Back to the Home Page</Button>
            </Link>
          )}
        </div>
      ) : (
        <>
          <div
            className="banner"
            style={{
              backgroundImage: `url(${tvShowSeasonEpisodeItem.background ? tvShowSeasonEpisodeItem.background : tvShowSeasonEpisodeItem.cover})`,
            }}
          >
            <div className="movie-content media-container">
              <div className="movie-content__info">
                <h1
                  className="title"
                  style={{
                    fontSize: getTitleFontSize(
                      tvShowSeasonEpisodeItem.title ||
                        tvShowSeasonEpisodeItem.name,
                    ),
                  }}
                >
                  {tvShowSeasonEpisodeItem.title ||
                    tvShowSeasonEpisodeItem.name}
                </h1>
                {tvShowSeasonEpisodeItem.originalTitle ? (
                  <h3
                    className="original-title"
                    style={{
                      fontSize: getOriginalTitleFontSize(
                        tvShowSeasonEpisodeItem.originalTitle,
                      ),
                    }}
                  >
                    Original Title: {tvShowSeasonEpisodeItem.originalTitle}
                  </h3>
                ) : null}
                <h1 className="subtitle">
                  Season {tvShowSeasonEpisodeItem?.season?.number}
                </h1>
                <h1 className="subtitle">
                  {tvShowSeasonEpisodeItem?.season?.tvShow?.title}
                </h1>

                <div className="genres">
                  <span className="badge genres__item">
                    {tvShowSeasonEpisodeItem.releaseDate}
                  </span>
                  <span className="badge genres__item">
                    {tvShowSeasonEpisodeItem.runtime}
                  </span>
                  <span className="badge genres__item">
                    {tvShowSeasonEpisodeItem?.season?.tvShow?.rating &&
                      tvShowSeasonEpisodeItem.season.tvShow.rating.name}
                  </span>
                  <span className="badge genres__item">
                    IMDB Rating: {tvShowSeasonEpisodeItem.imdbRating}
                  </span>
                  <span className="badge genres__item">
                    {tvShowSeasonEpisodeItem.quality &&
                      tvShowSeasonEpisodeItem.quality.name}
                  </span>
                  <span className="badge genres__item">
                    {tvShowSeasonEpisodeItem.movieLanguages &&
                      tvShowSeasonEpisodeItem.movieLanguages[0].language.name}
                  </span>
                </div>

                <p
                  className="overview"
                  style={{
                    fontSize: getOverviewFontSize(
                      tvShowSeasonEpisodeItem.description,
                    ),
                  }}
                >
                  {tvShowSeasonEpisodeItem.description}
                </p>

                <div className="btns">
                  <Button onClick={toggleTVShowSeasonEpisodeModal}>
                    Watch now
                  </Button>
                  <OutlineButton onClick={toggleTrailerModal}>
                    Watch trailer
                  </OutlineButton>{' '}
                </div>
              </div>

              <div className="movie-content__poster">
                <a
                  href={tvShowSeasonEpisodeItem.cover}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="movie-content__poster__img_tv_show"
                    src={tvShowSeasonEpisodeItem.cover}
                    alt={
                      tvShowSeasonEpisodeItem.title ||
                      tvShowSeasonEpisodeItem.name
                    }
                  ></img>
                </a>
              </div>
            </div>
          </div>

          <div className="movie-content-description">
            {tvShowSeasonEpisodeItem.originalTitle ? (
              <h3
                className="original-title"
                style={{
                  fontSize: getOriginalTitleFontSize(
                    tvShowSeasonEpisodeItem.originalTitle,
                  ),
                }}
              >
                Original Title: {tvShowSeasonEpisodeItem.originalTitle}
              </h3>
            ) : null}
            <p
              className="overview"
              style={{
                fontSize: '1rem',
              }}
            >
              {tvShowSeasonEpisodeItem.description}
            </p>
          </div>

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Directors'}</h2>
              </div>
              <ArtistList
                artists={tvShowSeasonEpisodeItem.episodeDirectors}
                category="director"
              />
            </div>
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Guest Stars'}</h2>
              </div>
              <ArtistList
                artists={tvShowSeasonEpisodeItem.episodeGuestStars}
                category="actor"
              />
            </div>
          </div>

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Screenwriters'}</h2>
              </div>
              <ArtistList
                artists={tvShowSeasonEpisodeItem.episodeScreenwriters}
                category="screenwriter"
              />
            </div>
          </div>

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Genres'}</h2>
              </div>
              <div className="genres">
                {tvShowSeasonEpisodeItem.genres &&
                  tvShowSeasonEpisodeItem.genres.map(
                    (genre: any, i: number) => (
                      <span key={i} className="badge genres__item">
                        {genre.name}
                      </span>
                    ),
                  )}
              </div>
              <div className="media-section__header">
                <h2 className="subtitle">{'Languages'}</h2>
              </div>
              <div className="genres">
                {tvShowSeasonEpisodeItem.episodeLanguages &&
                  tvShowSeasonEpisodeItem.episodeLanguages.map(
                    (episodeLanguage: any, i: number) =>
                      episodeLanguage.audioTrack && (
                        <span key={i} className="badge genres__item">
                          {episodeLanguage.language.name}
                        </span>
                      ),
                  )}
              </div>

              <div className="media-section__header">
                <h2 className="subtitle">{'Subtitles'}</h2>
              </div>
              <div className="genres">
                {tvShowSeasonEpisodeItem.episodeLanguages &&
                  tvShowSeasonEpisodeItem.episodeLanguages.map(
                    (episodeLanguage: any, i: number) =>
                      episodeLanguage.subtitle && (
                        <span key={i} className="badge genres__item">
                          {episodeLanguage.language.name}
                        </span>
                      ),
                  )}
              </div>
            </div>

            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Additional Information'}</h2>
              </div>
              <div className="genres">
                <span className="badge genres__item">
                  Studio:{' '}
                  {tvShowSeasonEpisodeItem.studio &&
                    tvShowSeasonEpisodeItem.studio.name}
                </span>
              </div>
              <div className="genres">
                <p className="overview">
                  Copyright: {tvShowSeasonEpisodeItem.copyright}
                </p>
              </div>

              <div className="genres">
                <OutlineButton
                  onClick={() => copyToClipboard()}
                  className="secondary-button"
                >
                  <img src={copyIcon} width="25" height="25" alt="" />
                </OutlineButton>
                <OutlineButton
                  onClick={() => window.open(background, '_blank')}
                  className="secondary-button"
                >
                  <img src={backgroundIcon} width="25" height="25" alt="" />
                </OutlineButton>

                <OutlineButton
                  onClick={() =>
                    markAsWatched(episodeUserItem?.watched ? false : true)
                  }
                  className="secondary-button"
                >
                  <img
                    src={episodeUserItem?.watched ? eyeFilledIcon : eyeIcon}
                    width="25"
                    height="25"
                    alt=""
                  />
                </OutlineButton>
              </div>

              <div className="media-section__header">
                <h2 className="subtitle">{'Tags'}</h2>
              </div>
              <div className="genres">
                {tvShowSeasonEpisodeItem.tags &&
                  tvShowSeasonEpisodeItem.tags.map((tag: any, i: number) => (
                    <span key={i} className="badge genres__item">
                      {tag.name}
                    </span>
                  ))}
              </div>
              <div className="media-section__header">
                <h2 className="subtitle">{'Collections'}</h2>
              </div>
              <div className="genres">
                {tvShowSeasonEpisodeItem.collections &&
                  tvShowSeasonEpisodeItem.collections.map(
                    (collection: any, i: number) => (
                      <span key={i} className="badge genres__item">
                        {collection.name}
                      </span>
                    ),
                  )}
              </div>
            </div>
          </div>

          <div className="detail-container">
            <div className="media-section mt-3">
              <div className="media-section__header">
                <h2 className="subtitle">Similar</h2>
              </div>
              {renderSimilarItems()}
            </div>
          </div>
          <TrailerModal
            isOpen={openTrailerModal}
            toggle={toggleTrailerModal}
            item={tvShowSeasonEpisodeItem}
          />
          <MediaModal
            isOpen={openTVShowSeasonEpisodeModal}
            toggle={toggleTVShowSeasonEpisodeModal}
            item={tvShowSeasonEpisodeItem}
            userItem={episodeUserItem}
          />
        </>
      )}
    </div>
  );
};

import { Button, FormSelect } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { IStep2FormInput } from '../../../../interfaces/forms/AutographForm';
import {
  setCurrentAutographStep,
  setGeneralAutographData,
} from '../../../../app/slices/addAutographSlice';

export const AutographForm = () => {
  const dispatch = useAppDispatch();

  const currencies = useAppSelector(
    (state: RootState) => state.addAutograph.currencies,
  );
  const sizes = useAppSelector((state: RootState) => state.addAutograph.sizes);
  const orientations = useAppSelector(
    (state: RootState) => state.addAutograph.orientations,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep2FormInput>({});

  // const [createAutograph, { isLoading, isError, isSuccess, error }] =
  // useCreateAutographMutation();

  const onSubmit: SubmitHandler<IStep2FormInput> = (formdata) => {
    // createAutograph(formdata).then(function (result: any) {
    //   if (result && result.data) {
    //     dispatch(setGeneralAutographData(formdata));
    //     dispatch(setNewAutographId(result.data.autographId));
    //     dispatch(setCurrentAutographStep(2));
    //   }
    // });

    dispatch(setGeneralAutographData(formdata));
    dispatch(setCurrentAutographStep(3));

    //   }
    // })
    // console.log('DATA MOVIE STEP 4:', formdata);
  };

  // {
  //   isLoading &&
  //     Swal.fire({
  //       title: 'Loading',
  //       allowOutsideClick: false,
  //       didOpen: () => {
  //         Swal.showLoading();
  //       },
  //     });
  // }

  // console.log('IS LOADING:', isLoading);

  // {
  //   isSuccess &&
  //     // Swal.fire({
  //     //   title: 'Logged in succesfully',
  //     //   text: 'Redirecting to home page',
  //     //   icon: 'success',
  //     //   timer: 1500,
  //     //   showConfirmButton: false,
  //     // });
  //     Swal.close();
  // }

  // const isLoginError = (error: any): error is ILoginFormError =>
  //   'status' in error;

  // {
  //   isError &&
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Oops...',
  //       text: 'Something went wrong!',
  //       confirmButtonColor: 'green',
  //       footer: isLoginError(error)
  //         ? (error as ILoginFormError).data?.Error ||
  //           (error as ILoginFormError).error
  //         : 'An error ocurred',
  //     });
  // }

  return (
    <div className="big-form-container">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="title">Title *</Label>
              <Controller
                name="title"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="title"
                    placeholder="Enter the autograph's title"
                    type="text"
                    invalid={!!errors.title}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The autograph's title is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="size">Size *</Label>
              <Controller
                name="size"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={sizes}
                    placeholder="Select the autograph's size"
                    error={errors.size}
                  />
                )}
              />
              {errors.size && (
                <div className="invalid-message">
                  {"The autograph's size is required"}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="orientation">Orientation *</Label>
              <Controller
                name="orientation"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={orientations}
                    placeholder="Select the autograph's orientation"
                    error={errors.orientation}
                  />
                )}
              />
              {errors.orientation && (
                <div className="invalid-message">
                  {"The autograph's orientation is required"}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={10}>
            <FormGroup>
              <Label for="dateSigned">Date Signed</Label>
              <Controller
                name="dateSigned"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="dateSigned"
                    placeholder="Enter the date the autograph was signed"
                    type="date"
                    invalid={!!errors.dateSigned}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
              <Label for="exactDate">Exact Date?</Label>
              <Controller
                name={'exactDate'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    type="checkbox"
                    style={{ marginTop: '10px' }}
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="placeSigned">Place Signed</Label>
              <Controller
                name="placeSigned"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="placeSigned"
                    placeholder="Enter the place where the autograph was signed"
                    type="text"
                    invalid={!!errors.placeSigned}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="currency">Currency</Label>
              <Controller
                name="currency"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={currencies}
                    placeholder="Select the currency"
                    error={errors.currency}
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="price">Price</Label>
              <Controller
                name="price"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="price"
                    placeholder="Enter the price"
                    type="number"
                    invalid={!!errors.price}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </div>
  );
};
